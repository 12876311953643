import Ee from "../assets/ee.png"
import TheFragrance from "../assets/the-fragrance-shop-bg-tr.png"
import Wild from "../assets/wild.png"
import SecretSales from "../assets/secret-sales.png"
import Ninja from '../assets/Ninja_Kitchen_logo.webp'
import plusnet from "../assets/plusnet.png"
import BalanceCoffe from "../assets/balance-coffee.png"
import InnerMost from "../assets/inner-most.jpg"
import Jimmys from "../assets/jimmys.png"
import Kocktail from "../assets/kocktail.png"
import Shark from "../assets/Shark_logo_black.png"
import superdrug from "../assets/superdrug.png"
import visionexpress from "../assets/visionexpress.png"
import Evri from "../assets/evri.png"

const homepageTestimonials = [
  {
    id: "ee",
    alt: "",
    img: Ee,
  },
  {
    id: "wild",
    alt: "",
    img: Wild,
  },
  {
    id: "secretsales",
    alt: "",
    img: SecretSales,
  },
  {
    id: "ninja",
    alt: "",
    img: Ninja,
  },
  {
    id: "shark",
    alt: "",
    img: Shark,
  },
  {
    id: "plusnet",
    alt: "",
    img: plusnet,
  },
  {
    id: "superdrug",
    alt: "",
    img: superdrug,
  },
  {
    id: "visionexpress",
    alt: "",
    img: visionexpress,
  },
  {
    id: "evri",
    alt: "",
    img: Evri,
  },
  {
    id: "the-fragrance",
    alt: "",
    img: TheFragrance,
  }
]

const gopageTestimonials = [
  {
    
    id: "kocktail",
    alt: "",
    img: Kocktail,
  },
  {
    id: "balance-coffee",
    alt: "",
    img: BalanceCoffe,
  },
  {
    id: "inner-most",
    alt: "",
    img: InnerMost,
  },
  {
    id: "jimmys",
    alt: "",
    img: Jimmys,
  },
]

export {
  homepageTestimonials,
  gopageTestimonials
}
